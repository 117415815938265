import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // shadows: Array(25).fill('none'),
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: { fontWeight: 400 },
    h1: {
      fontSize: 72,
      lineHeight: '90px',
      fontWeight: 600,
    },
    h2: {
      fontSize: 60,
      lineHeight: '72px',
      fontWeight: 600,
    },
    h3: {
      fontSize: 48,
      lineHeight: '60px',
      fontWeight: 600,
    },
    h4: {
      fontSize: 36,
      lineHeight: '44px',
      fontWeight: 600,
    },
    h5: {
      fontSize: 30,
      lineHeight: '38px',
      fontWeight: 600,
    },
    h6: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: '30px',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 18,
      lineHeight: '28px',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
    },
    button: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
      textTransform: 'none',
    },
    caption: {
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 500,
    },
    overline: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: '#0E1DAC',
    },
    secondary: {
      main: '#030729',
    },
    error: {
      main: '#B42318',
    },
    info: {
      main: '#E7E9FF',
      dark: '#E4E5F2',
      light: '#F0F1FF',
    },
    grey: {
      '50': '#FCFCFD',
      '100': '#F9FAFB',
      '200': '#F2F4F7',
      '300': '#E4E5F2',
      '400': '#DCDDE9',
      '500': '#9194AF',
      '600': '#616581',
      '700': '#434661',
    },
    success: {
      main: '#027A48',
      dark: '#12B76A',
      light: '#ECFDF3',
    },
    text: {
      primary: '#030729',
      secondary: '#616581',
    },
  },
});

export default theme;
