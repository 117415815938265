import Box from '@mui/material/Box';
import { ReactNode } from 'react';

const InnerWrapper = ({
  title,
  children,
  disablePadding,
}: {
  title?: ReactNode;
  children: ReactNode;
  disablePadding?: boolean;
}) => {
  if (title) {
    return (
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {title}
        <Box
          sx={{
            p: {
              xs: disablePadding ? 0 : 1,
              md: 3,
            },
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: {
          xs: 1,
          md: 3,
        },
      }}
      id="inner-wrapper"
    >
      {children}
    </Box>
  );
};

export default InnerWrapper;
