// import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Hidden, Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import CustomScrollbar from './CommonScrollbar';
import MainListItems from './listItems';

const drawerWidth = 280;

const StyledToolbar = styled(Toolbar)(({ theme }: { theme: Theme }) => ({
  height: '72px',
  minHeight: '72px',
  [theme.breakpoints.down('md')]: {
    height: '64px',
    minHeight: '64px',
  },
}));

const Wrapper = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();

  const { title } = location?.state || { title: 'DoorFeed Admin portal' };

  const drawer = (
    <div>
      <StyledToolbar>
        <a href="/">
          <img src="/DoorFeed_Admin_Portal_Logo.svg" alt="logo" />
        </a>
      </StyledToolbar>
      <Divider />
      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <List>
        <MainListItems open handleDrawerToggle={handleDrawerToggle} />
      </List>
    </div>
  );

  return (
    <Box height={1} sx={{ display: 'flex' }}>
      <CssBaseline />
      <Hidden mdUp>
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
            background: '#030729',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="subtitle2" noWrap component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
            '& .MuiPaper-root': {
              background: '#030729',
              color: '#FFFFFF',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            },
            '& a': {
              textDecoration: 'none',
              color: '#FFFFFF',
            },
          }}
        >
          <CustomScrollbar>{drawer}</CustomScrollbar>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
            '& .MuiPaper-root': {
              background: '#030729',
              color: '#FFFFFF',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            },
            '& a': {
              textDecoration: 'none',
              color: '#FFFFFF',
            },
          }}
          open
        >
          <CustomScrollbar>{drawer}</CustomScrollbar>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Hidden mdUp>
          <Toolbar />
        </Hidden>
        <CustomScrollbar customColor="#030729">
          <Outlet />
        </CustomScrollbar>
      </Box>
    </Box>
  );
};

export default Wrapper;
