import loadable from '@loadable/component';

export const AssetManagementAccounting = loadable(
  () => import('./asset-management/accounting')
);
export const AssetManagementDocuments = loadable(
  () => import('./asset-management/documents')
);
export const Buyer = loadable(() => import('./buyer'));
export const Buyers = loadable(() => import('./buyers'));
export const CreateTenant = loadable(() => import('./tenant/create'));
export const Dashboard = loadable(() => import('./Dashboard'));
export const DataInsights = loadable(() => import('./data-insights'));
export const Entities = loadable(() => import('./entities'));
export const Entity = loadable(() => import('./entities/entity'));
export const Login = loadable(() => import('./login'));
export const Management = loadable(() => import('./management'));
export const Organigram = loadable(() => import('./entities/organigram'));
export const Partner = loadable(() => import('./partners/partner'));
export const Partners = loadable(() => import('./partners'));
export const Pipeline = loadable(() => import('./pipeline'));
export const Portfolio = loadable(
  () => import('./portfolio-manager/portfolio')
);
export const PortfolioManager = loadable(() => import('./portfolio-manager'));
export const Property = loadable(() => import('./property'));
export const Tenancy = loadable(() => import('./tenancy'));
export const Tenant = loadable(() => import('./tenant'));
export const Tenants = loadable(() => import('./tenants'));
export const Test = loadable(() => import('./test'));
export const Trends = loadable(() => import('./trends'));
export const User = loadable(() => import('./users/user'));
export const Users = loadable(() => import('./users'));
export const PropertiesPublishing = loadable(
  () => import('./property-publishing')
);
export const PropertyPublishing = loadable(
  () => import('./property-publishing/property-publishing')
);
export const ViewerPayments = loadable(() => import('./viewer-payments'));
export const Viewers = loadable(() => import('./viewers'));
export const Viewing = loadable(() => import('./viewing'));
export const Viewings = loadable(() => import('./viewings'));
