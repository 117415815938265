import { Auth } from 'aws-amplify';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface AuthContextType {
  user: any;
  signin: (user: any) => void;
  signout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  user: {},
  signin: () => {
    return null;
  },
  signout: () => {
    return null;
  },
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setAuthenticatedUser] = useState<any>(null);
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    let didCancel = false;
    const getUser = async (): Promise<void> => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (cognitoUser) {
          if (!didCancel) {
            setAuthenticatedUser(cognitoUser);
          }
        } else {
          if (!didCancel) {
            setAuthenticatedUser(null);
          }
          navigate('/login');
        }
      } catch (e) {
        console.log(e);
        if (!didCancel) {
          setAuthenticatedUser(null);
        }
        navigate('/login');
      }
    };

    getUser();
    return () => {
      didCancel = true;
    };
  }, [navigate]);

  const value = useMemo(() => {
    const signin = (newUser: string) => {
      setAuthenticatedUser(newUser);
      navigate('/');
    };

    const signout = async () => {
      await Auth.signOut({ global: true });
      navigate('/login');
    };

    return { user, signin, signout };
  }, [navigate, user]);

  if (!user && pathname !== '/login') {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
