import './App.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';

import { AuthProvider } from './components/authorization/Auth';
import { ErrorFallback } from './components/common/ErrorFallback';
import { NoMatch } from './components/common/NoMatch';
import Wrapper from './components/common/Wrapper';
import {
  AssetManagementAccounting,
  AssetManagementDocuments,
  Buyer,
  Buyers,
  CreateTenant,
  Dashboard,
  DataInsights,
  Entities,
  Entity,
  Login,
  Management,
  Organigram,
  Partner,
  Partners,
  Pipeline,
  Portfolio,
  PortfolioManager,
  PropertiesPublishing,
  Property,
  PropertyPublishing,
  Tenancy,
  Tenant,
  Tenants,
  Test,
  Trends,
  User,
  Users,
  ViewerPayments,
  Viewers,
  Viewing,
  Viewings,
} from './pages';
import theme from './styles/theme';

const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'https://www.doorfeed.com';
    }
  }, []);

  if (process.env.NODE_ENV === 'development') {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={error => {
          if (/Loading chunk [\d]+ failed/.test(error.message)) {
            window.location.reload();
          }
        }}
        onReset={() => {
          window.location.reload();
        }}
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes>
                <Route path="/" element={<Wrapper />}>
                  <Route index element={<Dashboard />} />
                  <Route path="viewers" element={<Viewers />} />
                  <Route path="viewer-payments" element={<ViewerPayments />} />
                  <Route path="viewings" element={<Viewings />} />
                  <Route path="/viewings/:viewerviewid" element={<Viewing />} />
                  <Route
                    path="portfolio-manager"
                    element={<PortfolioManager />}
                  />
                  <Route
                    path="portfolio/:entityid/:portfolioid"
                    element={<Portfolio />}
                  />
                  <Route path="pipeline" element={<Pipeline />} />
                  <Route path="buyers" element={<Buyers />} />
                  <Route path="buyers/:buyerid" element={<Buyer />} />
                  <Route path="entities/:entityid" element={<Entity />} />
                  <Route path="entities" element={<Entities />} />
                  <Route path="partners" element={<Partners />} />
                  <Route path="partners/:id" element={<Partner />} />
                  <Route path="organigram" element={<Organigram />} />
                  <Route path="users" element={<Users />} />
                  <Route path="users/:userId" element={<User />} />
                  <Route
                    path="properties-publishing"
                    element={<PropertiesPublishing />}
                  />
                  <Route
                    path="property-publishing/:propertyid"
                    element={<PropertyPublishing />}
                  />
                  <Route path="management" element={<Management />} />
                  <Route path="management/:id" element={<Property />} />
                  <Route path="trends" element={<Trends />} />
                  <Route path="test" element={<Test />} />
                  <Route path="data-insights" element={<DataInsights />} />
                  <Route
                    path="property-management/tenants"
                    element={<Tenants />}
                  />
                  <Route
                    path="property-management/tenants/create"
                    element={<CreateTenant />}
                  />
                  <Route
                    path="property-management/tenants/:id"
                    element={<Tenant />}
                  />
                  <Route
                    path="property-management/tenancy/:id"
                    element={<Tenancy />}
                  />
                  <Route path="asset-management">
                    <Route
                      path="accounting"
                      element={<AssetManagementAccounting />}
                    />
                    <Route
                      path="documents"
                      element={<AssetManagementDocuments />}
                    />
                  </Route>
                  <Route path="*" element={<NoMatch />} />
                </Route>
                <Route path="/login" element={<Login />} />
              </Routes>
            </ThemeProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    );
  }

  return null;
};

export default App;
