import './index.css';

import { Amplify } from 'aws-amplify';
import dotenv from 'dotenv';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

dotenv.config();

Amplify.configure({
  aws_project_region: 'eu-west-3',
  aws_cognito_region: 'eu-west-3',
  aws_user_pools_id: 'eu-west-3_7e4rl1z6x',
  aws_user_pools_web_client_id: '61uud59542nkagsd8u3977ltrf',
  authenticationFlowType: 'CUSTOM_AUTH',
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
